<template>
    <div class="tracking">
        <b-container fluid class="z-background-light-grey h-100">
            <b-row>
                <b-col
                    class="form-side d-flex justify-content-center align-items-start p-5 px-md-0"
                    md="6"
                    style="background-color: #F3F5F9"
                >
                    <div
                        class="form-section-container d-md-flex justify-content-md-end p-md-3 mt-4"
                    >
                        <div class="w-100 text-md-left">
                            <h3
                                style="font-size: 12px; font-weight: 600; letter-spacing: 1px;"
                                class="h6 text-uppercase z-text-light z-text-bold"
                            >
                                Mándalo con zubut
                            </h3>
                            <p class="hero-message z-text-bigger z-text-normal">
                                Rastrea tus envíos
                            </p>
                            <b-alert
                                :show="showErrorAlert"
                                variant="warning"
                                class="z-text-smaller"
                            >
                                Debes ingresar un ID de rastreo
                            </b-alert>
                            <b-form-input
                                v-model="trackingId"
                                placeholder="ID de rastreo"
                                class="tracking-input py-4"
                            ></b-form-input>
                            <z-button
                                @click="openTracking"
                                class="tracking-button mt-4 w-auto"
                                size="lg"
                                uppercase
                                bold
                            >
                                Rastrear
                            </z-button>
                        </div>
                    </div>
                </b-col>
                <b-col md="6" class="p-0">
                    <div id="driver-image"></div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import { BAlert } from "bootstrap-vue";

export default {
    name: "Tracking",

    components: {
        BAlert
    },

    data() {
        return {
            showErrorAlert: false,
            trackingId: ""
        };
    },

    methods: {
        openTracking() {
            const UUID_FORMAT = 36;

            if (!this.trackingId) {
                this.showErrorAlert = true;
            } else {
                this.showErrorAlert = false;
                if (this.trackingId.length < UUID_FORMAT) {
                    this.trackingId = this.inflateUUID(this.trackingId);
                }
                window.open(
                    `${process.env.VUE_APP_WEB_APP_URL}/tracking/parcel/${this.trackingId}`
                );
            }
        },

        /**
         * Inserts '-' characters to form a UUID from its deflated form.
         * @param {string} id A deflated UUID as a string.
         */
        inflateUUID(id) {
            const UUID_HYPHEN_INVERSE_DISTANCE = [12, 4, 4, 4];
            let inflatedId = id;
            let index = id.length;
            for (const distance of UUID_HYPHEN_INVERSE_DISTANCE) {
                index -= distance;
                inflatedId = inflatedId.slice(0, index) + "-" + inflatedId.slice(index);
            }
            return inflatedId;
        }
    }
};
</script>

<style lang="scss">
.tracking {
    #driver-image {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: url("../assets/img/drivers-4.jpeg");
    }

    .tracking-input {
        box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1);
    }

    .tracking-button {
        padding: 1.6em 2em;
        line-height: 0px;
    }

    .form-section-container {
        width: 100%;
        max-width: 360px;
        margin-right: auto;
        margin-left: auto;
    }

    @include media-breakpoint-up(md) {
        #driver-image {
            height: 500px;
        }

        .form-section-container {
            max-width: 360px;
        }
    }

    @include media-breakpoint-up(lg) {
        .form-section-container {
            max-width: 480px;
        }
    }

    @include media-breakpoint-up(xl) {
        #driver-image {
            height: calc(100vh - 68px);
        }

        .form-section-container {
            max-width: 420px;
        }
    }
}
</style>
